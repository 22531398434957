import ali from 'src/assets/case_study/ali_.png'
import beforeImg from 'src/assets/case_study/before.png'
import eye from 'src/assets/case_study/eyes.jpg'
import haley from 'src/assets/case_study/haley.png'
import gallery from 'src/assets/case_study/image.png'
import demadImg1 from 'src/assets/case_study/image1.png'
import demadImg2 from 'src/assets/case_study/image2.png'
import demadImg4 from 'src/assets/case_study/image4.png'
import OnDemandImg1 from 'src/assets/case_study/img10.png'
import OpsheroImg2 from 'src/assets/case_study/img5.png'
import OpsheroImg1 from 'src/assets/case_study/img6.png'
import Image2 from 'src/assets/case_study/img7.png'
import Image3 from 'src/assets/case_study/img8.png'
import OnDemandHeroImg from 'src/assets/case_study/img9.png'
import keithImg from 'src/assets/case_study/keith.png'
import LadyImg from 'src/assets/case_study/lady.png'
import onDemandlogo from 'src/assets/case_study/logo2.png'
import book from 'src/assets/case_study/menu_book.png'
import ray from 'src/assets/case_study/ray.png'
import TruROIImg from 'src/assets/case_study/truroi.png'
import vector from 'src/assets/case_study/vector.png'
import hook from 'src/assets/case_study/webhook.png'
import aygChargebacks from 'src/assets/marketing/aygChargebacks.png'
import afterImg from 'src/assets/svg/after.svg'
import arrow from 'src/assets/svg/arrow.svg'
import Opslogo from 'src/assets/svg/bars.svg'
import Image1 from 'src/assets/svg/img34.svg'
import demadImg3 from 'src/assets/svg/img36.svg'
import KGRlogo from 'src/assets/svg/magic_exchange.svg'
import setting from 'src/assets/svg/setting.svg'

export const KGRchargebacks = {
  logo: KGRlogo,
  title1: 'Error Correction Workflows',
  title2: 'Rally’s (AZ) reduces order errors and chargebacks by 60% using Loop',
  bgColor: 'rgba(172, 167, 232, 1)',
  heroImg: '/hero/chargeback.png',
  Image1: Image1,
  testimonial: {
    img: keithImg,
    name: 'Keith Gallangher',
    designation: 'Owner',
    words:
      '“Prior to engaging Loop, we spent several hours per day researching chargebacks, order errors and customer refunds, phoning delivery services and coordinating with stores. Loop automated the entire process, saving us thousands of dollars and hours.”'
  },
  boxWidth: 'md:w-[25%]',
  boxes: [
    {
      title: 'Order Accuracy',
      value: '+66%',
      desc: ''
    },
    {
      title: 'Saved',
      value: '10hrs+',
      desc: 'per week'
    },
    {
      title: 'Delivery Profitability',
      value: '+30%',
      desc: ''
    }
  ],
  types: [
    {
      title: 'Error rates dropped',
      value: '50%'
    },
    {
      title: 'Saved',
      value: '$5000/y'
    }
  ],
  introduction:
    "A Rally's location in Arizona, experienced a surge in delivery orders during the pandemic, with around 20% of orders coming through third-party delivery services. However, they lacked the data to understand inaccuracies, errors, and  customer feedback from these deliveries. This resulted in operational inefficiencies and missed opportunities to address customer concerns.",
  text: "Previously, Rally's had a team member dedicated to manually building detailed error reports and sharing them with the team. This process was time-consuming and did not provide real-time visibility into issues with third-party deliveries. The team spent over 10 hours each day on this redundant work, which took away valuable time from other essential tasks.",
  painPoint:
    "Lack of visibility and understanding of customer feedback and errors from third-party deliveries. Rally's faced challenges in accurately tracking and addressing issues related to orders and customer requests coming from these third-party platforms. This resulted in inefficiencies, lost revenue, and potential customer dissatisfaction.",
  solutionLabel: 'Solution & Results',
  solutionText:
    'The implementation of Loop yielded remarkable results for KGR:',
  text4:
    "Loop stepped in to solve these challenges by integrating with all third-party platforms and automating the reconciliation of orders and customer refunds. With Loop's solution, Rally's team gained access to a comprehensive daily list of chargebacks and order issues, allowing them to promptly address problems and improve customer satisfaction. The system also captured proof of delivery through images, enabling Rally's to effectively resolve customer disputes.  ",
  text1: "The impact of Loop's implementation was remarkable.",
  text2:
    "Rally's experienced a significant reduction in order error rates, leading to improved operational efficiency and reduced lost revenue. They were able to cut their error rates by more than half and save approximately $5,000 per year. Moreover, the automation provided by Loop freed up valuable time for the team, allowing them to focus on more productive tasks and build better systems and processes. This resulted in cost savings, increased visibility into orders, and enhanced customer service.",
  text3:
    "By leveraging Loop's platform, Rally's transformed their operations, achieved tangible results, and positioned themselves for continued growth and success in the competitive market of food delivery services."
}

export const Ops_Reports = {
  logo: Opslogo,
  title1: 'Weekly performance report case study',
  title2: 'Empowering the Frontline:',
  title3: "MIXT's Use of Loop to Empower Store Managers",
  bgColor: 'rgba(213, 247, 255, 1)',
  heroImg1: OpsheroImg1,
  heroImg2: OpsheroImg2,
  before: beforeImg,
  after: afterImg,
  testimonial: {
    img: LadyImg,
    name: 'Kat',
    designation: 'Director of Digital Channels at MIXT',
    words:
      "“Loop Operational Performance Reports have been a game changer for us. We're now able to access timely  insights and feedback from our customers, which has not only saved us countless hours but has also allowed us to deliver better service to our customers”"
  },
  boxWidth: 'md:w-1/3',
  boxes: [
    {
      title: 'Saved',
      value: '8-12hrs',
      desc: ''
    },
    {
      title: 'Reduced time to action by',
      value: '6 days',
      desc: ''
    }
  ],
  introduction:
    'MIXT, a popular salad restaurant chain with 10+ locations and two sub-brands, faced challenges related to collating and analyzing customer feedback from various platforms at scale. With reviews coming through Doordash, UberEats, Grubhub & Helpscout, having a solution that aggregates all of them in one place has empowered store managers to react quickly and deliver the high quality experience their customers have come to expect.',
  text_1:
    'Prior to implementing Loop, the operations team at MIXT would spend nearly 6-8 hours each week collecting and analyzing feedback data from four different platforms. This process was time-consuming and made deriving actionable insights a challenge. The team initially began using Loop to examine one category of delivery feedback. Over the last six months, they have expanded its use to consolidate data, automating collation, and generating actionable insights.',
  text_2:
    'These are now provided in concise, one-page weekly reports.Loop has empowered store managers to boost productivity, implement customized plans, and drive improvements across all locations. Thanks to the collaboration between MIXT and Loop, the operations team can address customer pain points effectively. They achieve this through the Loop Customer Reviews Module, powered by Chat GPT. This module aggregates and presents customer insights in real-time.Furthermore, Loop integrates seamlessly with their primary feedback provider, HelpScout. This integration simplifies the process for this customer-centric operator to manage and respond to all customer reviews simultaneously.',
  solutionLabel: 'Results',
  solutionText:
    'The implementation of Loop yielded remarkable results for MIXT:',
  solutionImg: Image2,
  types: [
    {
      img: setting,
      label: 'Improved Efficiency'
    },
    {
      img: arrow,
      label: 'Enhanced Customer Feedback Response Time:'
    },
    {
      img: eye,
      label: 'Increased Visibility and Performance Tracking'
    },
    {
      img: vector,
      label: 'Quantified Financial Impact'
    }
  ],
  text1:
    "At LOOP, we pride ourselves on delivering tangible results that make a real difference in the food delivery industry. Here's a glimpse of the concrete ways we have helped MIXT:",
  types2: [
    {
      img: book,
      title: 'Menu Accuracy:',
      label: 'Mandarin Salad Issue:',
      imgStyle: '-ml-4',
      list: [
        "Through LOOP's platform, we identified a mapping error that caused customers to receive the wrong protein in their orders.",
        'Customers ordering chicken on third-party platforms were getting steak due to the mapping mismatch.',
        'This resulted in customer complaints and confusion about their orders.',
        'Thanks to LOOP, we successfully rectified the mapping error, ensuring accurate protein selection for customers.'
      ]
    },
    {
      img: gallery,
      title: 'Photo Error:',
      label: 'Photo Error:',
      imgStyle: '-ml-8',
      list: [
        'Another challenge we faced was a photo error that caused confusion among customers.',
        "The picture displayed crispy chicken, which wasn't available at some of our stores.",
        'Customers would order based on the photo, leading to disappointment upon receiving a different salad.',
        'With the help of LOOP, we updated the photos to showcase the grilled chicken salad instead.',
        'This resolved the issue and significantly reduced order errors, ensuring customer satisfaction.'
      ]
    },
    {
      img: hook,
      title: 'Resolving Operational Challenges:',
      label: 'Opening a New Location in Mill Valley:',
      imgStyle: '-ml-8',
      list: [
        'When we opened a new location in Mill Valley, we encountered various issues with third-party platforms.',
        "Through LOOP's platform, we conducted a thorough analysis to identify the top 10 critical problems affecting performance.",
        'We then shared these insights with the general manager, area manager, and director of operations.',
        'This focused approach allowed us to address the most important issues, resulting in enhanced operations and improved star ratings.'
      ]
    }
  ],
  text2:
    'With the Loop Customer Reviews module, MIXT now has instant access to customer feedback, saving hundreds of hours of manual work previously required to gather the data. The module also enables all the GMs in the stores to stay on top of customer reviews, helping them improve their service and offerings.',
  text3:
    "By leveraging Loop's automation and AI capabilities, MIXT has experienced a transformative shift in its operations. The streamlined data collation process, coupled with Loop's actionable insights, has empowered store managers to boost productivity, implement customized plans, and drive continuous improvements across all locations."
}

export const AYGchargebacks = {
  logo: KGRlogo,
  title1: 'Error Correction Workflows',
  title2: 'How AYG Food Services solved order accuracy issues',
  bgColor: 'rgba(255, 241, 219, 1)',
  heroImg: aygChargebacks,
  Image1: Image3,
  testimonial: {
    img: '/testimonials/herbert.png',
    name: 'Geoffrey Herbert, President at AYG Food Services & ',
    designation: 'Board Member, Texas Restaurant Association',
    words:
      'Our 10 AYG Food Services  locations used to see a lof of unfulfilled order and faced the issue of friendly fraud. Our team found it hard to keep track of these order issues. Loop stepped in to automate our error correction workflows saving time and money'
  },
  boxWidth: 'md:w-fit md:max-w-[32%]',
  boxes: [
    {
      title: 'Order error rate improvement',
      value: '66%+ order accuracy',
      desc: ''
    },
    {
      title: 'Saved',
      value: '10hrs+',
      desc: 'per week'
    },
    {
      title: 'Delivery Profitability',
      value: '+10%',
      desc: ''
    }
  ],
  types: [
    {
      title: 'Error rates dropped',
      value: '50%'
    },
    {
      title: 'Saved',
      value: '$5000/y'
    }
  ],
  types2: {
    name: 'AYG',
    list: ['10-25 Locations', '2 Brands', 'Franchisee'],
    isLight: false
  },
  introduction:
    'AYG Food Services is a 10 unit franchisee based out of  Dallas growing rapidly. They were faced challenges with unfulfilled orders and limited visibility into order issues. Many orders were left unclaimed by third-party delivery services, resulting in inefficiencies and customer dissatisfaction.',
  text: 'Prior to integrating with Loop, AYG struggled with unfulfilled orders and lacked a robust system to address issues arising from third-party deliveries. They needed a solution that would provide better visibility into order status and streamline their operations.',
  painPoint:
    'AYG struggled with a high volume of  orders errors through third-party delivery services, resulting in wasted resources, operational inefficiencies, and diminished customer satisfaction.',
  solutionLabel: 'Solution & Results',
  solutionText:
    'The implementation of Loop yielded remarkable results for AYG:',
  text1:
    "Loop leveraged AYG Food Services' Envysion cameras strategically placed in their production and dispatch areas. These cameras captured valuable evidence of order preparation, ensuring accuracy and readiness for delivery. This evidence became crucial in resolving chargeback disputes effectively. Additionally, Loop's platform offered AYG comprehensive visibility into order issues across all their locations, empowering them to explore alternative solutions and proactively address customer concerns. As a result, AYG experienced improved order fulfilment rates, reduced chargebacks, and increased customer satisfaction.",
  text2:
    "In summary, Loop's integration with Envision cameras and comprehensive order management capabilities provided AYG with a powerful solution to address unfulfilled orders and enhance operational efficiency. The improved visibility and dispute resolution capabilities led to better customer satisfaction and overall business performance for AYG."
}

export const On_demand = {
  logo: onDemandlogo,
  title1: 'Digital Infra',
  title2: 'The On Demand Company:',
  title3: 'A Digital Transformation Journey',
  bgColor: 'rgba(209, 252, 226, 1)',
  heroImg2: OnDemandHeroImg,
  Image1: OnDemandImg1,
  testimonial: [
    {
      img: ali,
      name: 'ALI - COO The OnDemand Company',
      designation: '',
      words:
        "“Thanks to Loop's Financial Intelligence, we were able to automate and speed up our owner payouts for all our physical locations. Before, it used to take almost a month to process them, but now we can do it within one week, which is a huge time-saver. We've also eliminated over 100 hours of accounting work that was previously required to collate, analyze and process the payouts and commissions each week. Thanks to Loop's system, we now have better control over payouts.”",
      comma: false
    },
    {
      img: ray,
      name: 'RAY - Founder & CEO',
      designation: '',
      words:
        '“Team at Loop are awesome, they are keen in understanding and solving the payouts problem for us, with Loop’s financial reconciliation we were able to structure our payouts, investments in 3rd Party Marketing, Commissions, simply eliminating the leakages in our payouts leading to almost 30% more cash flow every month. I wholeheartedly recommend their services to anyone looking to optimize their finances”',
      comma: true
    },
    {
      img: haley,
      name: 'HALEY - COO The OnDemand Company',
      designation: '',
      words:
        '“I still get impressed every day with Loop imagining wherever we are at when we first started and where we are now.Previously we used to take 5-6 hours to audit one account and if we have call with an owner it takes 3-4 days of AEs time to prepare a report, with Loop we were able to audit all the 1000 in same week and conduct multiple periodic reviews, this has transformed our workflows in Customer Success in monitor the health of the accounts in one single dashboard”',
      comma: true
    }
  ],
  types2: {
    name: 'On Demand Company',
    list: ['500-1000 Locations', '750-1500 Brands', 'Virtual Brand'],
    isLight: false
  },
  boxWidth: 'md:w-[30%]',
  boxes: [
    {
      title: 'Time for store issue detection',
      value: '2 weeks to 1 hour',
      from: true,
      desc: '',
      bold: true
    },
    {
      title: 'Store network management',
      value: 'Single source of truth for store performance',
      desc: '',
      from: false,
      bold: false
    },
    {
      title: 'Payouts to nextwork',
      value: '8 weeks to 1 week',
      from: true,
      desc: '',
      bold: true
    }
  ],
  types: [
    {
      img: demadImg2,
      title: 'Financial Reconciliation',
      text: "The digital transformation journey with Loop brought forth several significant advancements for The On Demand Company. The most impactful change was the improvement in payment processing. With Loop's customizable revenue workflow modules, The On Demand Company was able to make timely and accurate payments to their customers, reducing payment processing delays from nearly two months to less than two weeks. Loop's reconciliation capabilities ensured the proper distribution of payouts, avoiding cash flow challenges and improving overall financial efficiency. Loop facilitated organizational re-structuring by automating the finance function which is now overseen by the head of operations",
      style: {
        border: '0.77px solid rgba(244, 244, 244, 1)',
        background: 'linear-gradient(0deg, #E2F1E8, #E2F1E8)'
      }
    },
    {
      img: demadImg4,
      img1: demadImg1,
      title: 'Store Availability',
      text: 'By providing real-time information on activated or deactivated stores, Loop offered valuable visibility to The On Demand Company. This enhanced visibility prevented operational disruptions and allowed them to  ensure that all stores were functioning properly, serving customers without any interruptions, and maximizing their operational efficiency.',
      style: {
        border: '0.77px solid rgba(203, 203, 203, 1)',
        background: 'linear-gradient(0deg, #FFE4BC, #FFE4BC)'
      }
    },
    {
      img: demadImg3,
      title: 'Store performance management',
      text: 'Loop’s error module empowered the team to aggregate, analyze, and troubleshoot performance issues at both macro and micro levels. By leveraging the module, The On Demand Company could address concerns promptly, provide targeted assistance to their owner network, and ensure a seamless customer experience. The comprehensive views of performance data, enabled data-driven decisions, improved operational efficiency, and enhanced customer satisfaction.',
      style: {
        border: '0.77px solid rgba(244, 244, 244, 1)',
        background: 'linear-gradient(0deg, #F8F8F8, #F8F8F8)'
      }
    }
  ],
  introduction:
    'In the ever-evolving world of virtual brands, The On Demand Company has stood the test of time growing their store network to 100s of locations and enabling 10s of millions in incremental solas. Their approach of creating personalized brands for each location based on their kitchen capability and capacity has allowed them to tap into untapped customer segments, attract new clientele, and differentiate themselves in the market. However, the rapid growth brought on by the COVID-19 pandemic presented several challenges. This case study explores how The On Demand Company tackled these obstacles and achieved transformative results through their partnership with Loop.',
  text: 'As one of the largest virtual brands, The On Demand Company had carved a niche for itself by creating customized brands for restaurants based on available resources, current competency, and market demand. By avoiding brand reuse and focusing on ground-up innovation, they successfully positioned their products and menus to attract specific customer segments. However, the sudden surge in store locations, expanding to nearly 1000 stores, introduced critical pain points that needed immediate attention.',
  painPointlist: [
    'Ensuring newly established stores were digitally operational and capable of serving customers posed a significant hurdle.',
    'Maintaining operational efficiency and error-free order processing across stores was vital.',
    'Timely and accurate payments to the owner network, based on individual permissions.'
  ],
  solutionLabel: 'Solution & Results',
  solutionText:
    'The implementation of Loop yielded remarkable results for TODC:',
  text1:
    "The implementation of Loop's marketing campaigns led to a 17% growth in sales for OnDemand. Owners now receive their payments twice as fast, with the payout time reduced from 8 weeks to 1 week. Additionally, the customer success team successfully runs operational checks their entire network in the same week, improving efficiency and ensuring accuracy. By reallocating budgets to high-performing campaigns across all platforms, OnDemand experienced revenue growth and maximized the impact of their marketing efforts.",
  text2:
    "In summary, Loop's integration with Envision cameras and comprehensive order management capabilities provided AYG with a powerful solution to address unfulfilled orders and enhance operational efficiency. The improved visibility and dispute resolution capabilities led to better customer satisfaction and overall business performance for AYG."
}

interface FixeSection {
  sectionType?: string
  listPageIcon?: string
  listPageImg?: string
  listPageTitle?: string
  listPageSubTitle?: string
  subTitleNewLine?: string
  bgColor?: string
  title?: string
  name?: string
  key?: string
  heroImg?: string
  heroIcon?: string
  backGroundColor?: string
  section?: FixeSectionItem[]
  imagePath?: string
  imageAlt?: string
  description?: string
  loopComments?: string
  iconImg?: string
  headingText?: string
  subHeadingText?: string
  imageSection?: ImageSection[]
}

interface ImageSection {
  key: string
  type: string
  imagePath: string
  isShow: boolean
}

interface FixeSectionItem {
  key: string
  type?: string
  text?: string | string[]
  isShow: boolean
}

/**
 * An array of objects representing different sections of the Fixe case study.
 * Each object contains information such as section type, title, description, and section content.
 */
export const Fixe: FixeSection[] = [
  {
    sectionType: 'listPage',
    listPageIcon: '/img/icons/menu_book.svg',
    listPageImg: '/img/hero/finance_overview.jpg',
    listPageTitle: 'Finances and Bookkeeping',
    listPageSubTitle: 'How Fixe automated 3PD Revenue Reconciliation ',
    subTitleNewLine: 'and saved 100s of hours using Loop',
    bgColor: 'rgba(255, 236, 219, 1)'
  },

  {
    sectionType: 'hero',
    name: 'Finances and Bookkeeping',
    key: 'hero',
    heroImg: '/img/hero/finance_overview.jpg',
    heroIcon: '/img/hero/fixeIcon.svg',
    backGroundColor: '#FFECDB',
    section: [
      {
        key: 'heroLabel',
        type: 'heroLabel',
        text: 'Finances and Bookkeeping',
        isShow: true
      },
      {
        key: 'heroHeading',
        type: 'heroHeading',
        text: 'How Fixe automated 3PD Revenue Reconciliation and saved 100s of hours using Loop',
        isShow: true
      }
    ]
  },
  {
    sectionType: 'quote',
    name: 'quote',
    imagePath: '/img/fixe-meet.jpg',
    imageAlt: 'Fixe',
    section: [
      {
        key: 'quoteText',
        type: 'quoteText',
        text: 'Many of our customers see 30-40% of their revenue coming from 3PD Delivery. However the 3P fees are blackbox and reconciling revenue would take hours per location. Loop automated 3PD bookkeeping, helping us save 100s of hours and bringing deep visibility into 3P Fees. This enabled us to make strategic decisions such as exclusivity and serve our customers better. We have now made Loop part of our tech stack and together we will power the future of restaurant bookkeeping',
        isShow: true
      },
      {
        key: 'quoteOwner',
        type: 'quoteOwner',
        text: ['Ryan Handel', 'CEO & Co Founder FIXE & Restaurant Owner'],
        isShow: true
      }
    ]
  },
  {
    sectionType: 'intro',
    key: 'introBackground',
    title: 'Background',
    description:
      'Fixe is a tech-forward restaurant book keeper that makes restaurant bookkeeping easy and affordable so that restaurants can focus on delightful experiences for their customers. They serve several popular and beloved brands across the country including Tacos 1986, Goop Kitchen and Pokeworks among other brands.',
    loopComments:
      "Before incorporating LOOP into their ecosystem, Fixe relied on manual bookkeeping processes to handle their clients' third-party delivery transactions. Account managers had to log into different dashboards for each delivery platform, extract relevant data, and perform complex calculations for commission fees, sales tax, and more. This labor-intensive process was time-consuming and prone to errors, hindering their ability to efficiently manage multiple restaurant clients."
  },
  {
    sectionType: 'background',
    section: [
      {
        key: 'backgroundHeading',
        type: 'heading',
        text: 'Background',
        isShow: true
      },
      {
        key: 'backgroundDescription',
        type: 'description',
        isShow: false,
        text: 'Fixe is a tech-forward restaurant book keeper that makes restaurant bookkeeping easy and affordable so that restaurants can focus on delightful experiences for their customers. They serve several popular and beloved brands across the country including Tacos 1986, Goop Kitchen and Pokeworks among other brands.'
      },
      {
        key: 'backgroundLoopComments',
        type: 'description',
        isShow: false,
        text: "Before incorporating LOOP into their ecosystem, Fixe relied on manual bookkeeping processes to handle their clients' third-party delivery transactions. Account managers had to log into different dashboards for each delivery platform, extract relevant data, and perform complex calculations for commission fees, sales tax, and more. This labor-intensive process was time-consuming and prone to errors, hindering their ability to efficiently manage multiple restaurant clients."
      }
    ]
  },
  {
    sectionType: 'painPoints',
    iconImg: 'img35.svg',
    headingText: 'Pain Points:',
    section: [
      {
        type: 'headingText',
        text: 'Pain Points:',
        key: 'painPointsHeading',
        isShow: false
      },
      {
        type: 'list',
        isShow: false,
        key: 'painPointList',
        text: [
          'Time-Consuming Bookkeeping: Manual handling of third-party delivery transactions resulted in significant time investment for account managers, limiting their ability to handle more clients effectively. ',
          'Error-Prone Processes: The complexity of dealing with various delivery platforms increased the chances of errors in bookkeeping and financial reporting.',
          'Lack of Consolidated Insights: The absence of a unified dashboard made it challenging for restaurant owners to get a comprehensive view of their third-party delivery performance, leading to suboptimal decision-making.'
        ]
      }
    ]
  },
  {
    sectionType: 'solutionAndResults',
    iconImg: 'solutionAndResults.svg',
    headingText: 'Solution & Results',
    subHeadingText:
      'The implementation of Loop yielded remarkable results for Fixe:',
    section: [
      {
        key: 'solutionAndResultsHeading',
        type: 'headingText',
        text: 'Solution & Results',
        isShow: false
      },
      {
        key: 'solutionAndResultsSubHeading',
        type: 'subHeadingText',
        text: 'The implementation of Loop yielded remarkable results for Fixe:',
        isShow: false
      },
      {
        key: 'solutionAndResultsList',
        type: 'list',
        isShow: false,
        text: [
          "By eliminating the need for manual data extraction and complex calculations, Loop saved Fixe's 5 hours per month per restaurant. This translates to $1000s of cost savings each month.",
          "With Loop's automated integration, the likelihood of human errors in bookkeeping significantly reduced, ensuring accurate financial reporting and reliable insights.",
          'Ryan and FIXE made a strategic decision adopting Loop as apart of their offerings to all of their clients. Loop makes it easy for any FIXE client to understand their 3PD statistics and make important data driven decisions to run their business.'
        ]
      }
    ],
    imageSection: [
      {
        key: 'fullSizeImage',
        type: 'full',
        imagePath: '/img/hero/bank_reconcilation.svg',
        isShow: true
      }
    ]
  }
]

export const RandRTruROI = {
  bgColor: '#fcd1e7',
  title1: 'TruROI',
  title2: 'Thriving in a world of 3PD Marketing with Data-Driven Precision',
  heroImg: TruROIImg
}
